const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',
  darkGreenColor:'#08683a',
  lightGreenColor:'#75b63d',
  primaryColor: '#08683a',
  fontColor: '#454949',
  bgColor: 'white',
  ...overloading
}

export default variables;