import { css } from '@emotion/core'
//import { mq } from '../../cssInJs'
export default{
    exTextItem : css({
        overflow:'hidden',
        maxHeight:'0',
        transition: 'all 0.8s',
        padding: '0px',
        '&.expand' : {
            maxHeight:'100%',
            padding: '1rem 0'
        },
        '.container' : {
            maxWidth:'unset',
            'div.htmlPanel' : {
                maxWidth: 'unset',
                marginLeft:'-15px',
                marginRight:'-15px'
            }
        }
    })
}