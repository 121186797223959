import { css  } from '@emotion/core'
import { mq, variables } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: variables.primaryColor,
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  // topScrolled: css(mq({
  //   height: [44, null, 56],
  // })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: [100, null, 120],
    marginTop: ['3.5rem', null, '3rem'],
    transition:'all .2s'
  })),
  logoScrolled: css(mq({
    'img' : mq({
      height: [44, null, 56],
      marginTop:'0px'
    })
  })),
}